<template>
<vx-card title="Blacklist Vehicles" id="comments">
    <template slot="actions">
        <vs-button @click="editSelected('')">Add</vs-button>
    </template>
    <vs-table :sst="true" @sort="handleSort" hoverFlat stripe :data="data" v-if="data.length">
        <template #thead>
            <vs-th sort-key="registrationPlate">Registration Plate</vs-th>
            <vs-th sort-key="vehicleModel">Vehicle Model</vs-th>
            <vs-th sort-key="status">Status</vs-th>
            <vs-th sort-key="modifiedDate">Date</vs-th>
            <vs-th sort-key="incidentDate">Incident Date</vs-th>
            <vs-th class="width1"></vs-th>
            <vs-th class="width1"></vs-th>
        </template>
        <template slot-scope="{data}">
            <vs-tr v-for="(tr, index) in data" :key="index" :data="tr">
                <vs-td><span @click="handleViewComments(tr.id)">{{tr.registrationPlate}}</span></vs-td>
                <vs-td>{{tr.vehicleModel}}</vs-td>
                <vs-td>
                    <span :class="tr.status === 'Blacklisted' ? 'text-danger': 'text-success'" >
                        {{ tr.status }}
                    </span>
                </vs-td>
                <vs-td>{{tr.modifiedDate | formatLongDate}}</vs-td>
                <vs-td><span v-if="tr.incidentDate != null">{{tr.incidentDate | formatLongDate}}</span></vs-td>
                <vs-td>
                    <vs-button v-if="tr.status === 'Blacklisted'" @click="revokeSelected(tr.id)" color="success">Revoke</vs-button>
                    <vs-button v-else @click="revokeSelected(tr.id)" color="danger">Blacklist</vs-button>
                </vs-td>
                <vs-td>
                    <vs-button @click="handleViewComments(tr.id, tr.registrationPlate )" color="warning">History</vs-button>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description" ></NoData>
    <ViewComments v-if="openSidebar" :openSidebar="openSidebar" :regPlate="currentRegPlate" :data="comments" @onClose="handleViewComments"></ViewComments>
</vx-card>
</template>

<script>
import {
    blacklistData
} from "../../store/api/blacklist"
import ViewComments from "./ViewComments"
import _ from 'lodash';

export default {
    data() {
        return {
            searchText: "",
            currentRegPlate: "",
            selectedPass: undefined,
            openSidebar: false,
            data: [],
            comments: [],
            noData: {
              title: "No Records Found",
            }
        }
    },
    components: {
      ViewComments
    },
    async created() {
        await this.loadVehicle()
    },
    methods: {
        async loadVehicle() {
            this.data = await blacklistData.search(this.searchText);
        },
        handleSort(key, active) {
          this.data = _.orderBy(this.data, key, active);
        },
        editSelected(id) {
            this.$router.push("/admin/blacklist/add/"+ id);
        },
        revokeSelected(id) {
            this.$router.push("/admin/blacklist/revoke/"+ id);
        },
        async handleViewComments(id, plate) {
            if(id){
                this.comments = await blacklistData.searchComments(id);
                this.currentRegPlate = plate
            }
            this.openSidebar = !this.openSidebar
        }
    }
}
</script>
